<template>
  <div class="cs-form r-6">
    <q-form @submit="onSubmit" ref="form">
      <div class="row q-col-gutter-md items-center text-center">
        <div class="col-12 col-md-12">
          <h3 class="q-ma-none text-dark title-h3">Contact Us</h3>
          <p class="q-ma-none text-dark text-caption"
            >We're here to solve your query</p
          >
        </div>
        <div class="col-12 col-md-6">
          <div class="form-field pb-0">
            <q-input
              outlined
              dense
              bg-color="white"
              v-model="customerDetails.firstName"
              label="First Name*"
              :rules="[
                (val) => validateRequired(val),
                validatePersonName(
                  customerDetails.firstName,
                  'Please enter a valid name'
                ),
              ]"
              :lazy-rules="lazyValidationRules"
              maxlength="40"
              aria-label="ftxcommerce"
              @input="handleFieldChange"
              :readonly="
                (!isLoggedIn && isReadOnly) ||
                (isLoggedIn && isFirstNameReadOnly)
              "
            />
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-field pb-0">
            <q-input
              outlined
              dense
              bg-color="white"
              v-model="customerDetails.lastName"
              label="Last Name*"
              :rules="[
                (val) => validateRequired(val),
                validatePersonName(
                  customerDetails.lastName,
                  'Please enter a valid name'
                ),
              ]"
              :lazy-rules="lazyValidationRules"
              maxlength="40"
              aria-label="ftxcommerce"
              @input="handleFieldChange"
              :readonly="
                (!isLoggedIn && isReadOnly) ||
                (isLoggedIn && isLastNameReadOnly)
              "
            />
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-field pb-0">
            <q-input
              outlined
              dense
              bg-color="white"
              v-model.trim="customerDetails.emailID"
              label="Email*"
              :rules="[
                (val) => validateRequired(val),
                (val) => validateEmail(val),
              ]"
              :lazy-rules="lazyValidationRules"
              maxlength="40"
              aria-label="ftxcommerce"
              @input="handleFieldChange"
              :readonly="
                (!isLoggedIn && isReadOnly) || (isLoggedIn && isEmailidReadOnly)
              "
            />
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-field pb-0">
            <q-input
              outlined
              bg-color="white"
              label="Phone Number"
              v-model="customerDetails.phoneNumber"
              v-mask="phoneMask"
              :rules="[validatePhone()]"
              :lazy-rules="lazyValidationRules"
              aria-label="ftxcommerce"
              @input="handleFieldChange"
              :readonly="
                (!isLoggedIn && isReadOnly) ||
                (isLoggedIn && isPhoneNumberReadOnly)
              "
            />
          </div>
        </div>
        <div class="col-12 col-md-12">
          <div class="form-field pb-0">
            <q-input
              outlined
              bg-color="white"
              v-model="customerDetails.subject"
              label="Subject"
              placeholder="Subject (Reason for Message)"
              maxlength="50"
              :rules="[(val) => validateRequired(val)]"
              :lazy-rules="lazyValidationRules"
              aria-label="ftxcommerce"
              @input="handleFieldChange"
              :readonly="isReadOnly"
            />
            <span class="float-right text-caption"
              >{{ customerDetails.subject.length }}/50</span
            >
          </div>
        </div>
        <div class="col-12 col-md-12">
          <div class="form-field__area pb-0">
            <q-input
              outlined
              bg-color="white"
              v-model="customerDetails.message"
              type="textarea"
              label="Enter Message"
              placeholder="Enter the Order Number for reference if you have a query related to the order"
              maxlength="500"
              :rows="10"
              autogrow
              :rules="[(val) => validateRequired(val)]"
              :lazy-rules="lazyValidationRules"
              aria-label="ftxcommerce"
              @input="handleFieldChange"
              :readonly="isReadOnly"
            />
            <span class="float-right text-caption"
              >{{ customerDetails.message.length }}/500</span
            >
          </div>
        </div>

        <!-- Continue Button (Before OTP is Requested) -->
        <div class="col-12 col-md-12">
          <div class="form-action pb-0">
            <q-btn
              unelevated
              no-caps
              rounded
              :label="isLoggedIn ? 'Submit' : 'Continue'"
              color="primary"
              class="q-btn-md full-width"
              @click="handleContinue"
              :loading="isLoggedIn ? loadingSubmit : loadingContinue"
            />
          </div>
        </div>
      </div>
    </q-form>

    <!-- Success Modal -->
    <q-dialog v-model="showSuccessModal" persistent>
      <q-card class="cs-success">
        <q-card-section>
          <q-btn
            flat
            round
            dense
            text-color="tertiary"
            icon="close"
            color="primary"
            @click="closeSuccessModal"
            class="absolute-top-right q-mt-sm q-mr-sm"
          />
          <SvgIcon size="90px" icon="cs-success" />
          <div class="text-body1 text-dark q-mt-md"
            >Your form is submitted successfully. Our store employee associates
            will contact you.</div
          >
        </q-card-section>
      </q-card>
    </q-dialog>
    <!-- Success Modal -->

    <!-- OTP Modal -->
    <q-dialog
      v-model="showOtpModal"
      :position="isMobile ? 'bottom' : 'standard'"
      persistent
    >
      <q-card class="cs-otp cs-success">
        <q-card-section>
          <q-btn
            flat
            round
            dense
            text-color="tertiary"
            icon="close"
            color="primary"
            @click="handleEdit"
            class="absolute-top-right q-mt-sm q-mr-sm"
          />
          <div
            class="text-18 text-weight-bold text-dark line-height-normal q-mb-md"
            >Verify your email for the form submission</div
          >
          <p>
            A 6-digit one-time password (OTP) is sent to
            <span class="text-secondary text-weight-bold">{{
              customerDetails.emailID
            }}</span>
          </p>
          <OTPInput
            ref="otpInput"
            v-model="otp"
            input-classes="otp-input q-mb-md"
            separator=""
            :num-inputs="6"
            :should-auto-focus="true"
            :is-input-num="true"
            @on-change="handleOtpChange"
          />
          <div>
            <span v-if="resendSeconds > 0 && !resending">
              Not received your code? Resend it in
              <span class="text-secondary text-weight-bold">{{
                formatOTPSeconds(resendSeconds)
              }}</span>
              s
            </span>
            <div v-if="resending"> Resending OTP... </div>
            <div v-else-if="resendSeconds <= 0" class="flex justify-center">
              <span> Didn't get OTP? </span>
              <q-btn
                type="button"
                :disable="resendSeconds > 0"
                unelevated
                no-caps
                label="Resend It"
                color="transparent"
                text-color="primary"
                class="no-hover text-underline line-height-normal q-ml-sm"
                size="16px"
                padding="0"
                @click="requestOtp"
              />
            </div>
          </div>
          <q-btn
            unelevated
            no-caps
            rounded
            type="submit"
            label="Verify & Submit"
            color="primary"
            class="full-width q-mt-md"
            size="16px"
            padding="sm md"
            :loading="loadingSubmit"
            @click="onSubmit"
          />
          <q-btn
            type="button"
            unelevated
            no-caps
            label="Edit Details"
            color="transparent"
            text-color="primary"
            class="no-hover text-underline"
            size="16px"
            padding="sm md"
            @click="handleEdit"
          />
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
export default {
  name: 'CustomerSupportForm',
  data() {
    return {
      customerDetails: {
        firstName: '',
        lastName: '',
        emailID: '',
        phoneNumber: '',
        subject: '',
        message: '',
      },
      showSuccessModal: false,
      loadingContinue: false,
      loadingSubmit: false,
      isCustomerDetailsReadOnly: false,
      otp: '',
      otpToken: '',
      otpRequested: false,
      resendSeconds: 0,
      defaultResendTime: 120,
      resending: false,
      formFilled: false,
      interval: null,
      isFirstNameReadOnly: false,
      isLastNameReadOnly: false,
      isEmailidReadOnly: false,
      isPhoneNumberReadOnly: false,
      otpAttemptsExceeded: false,
      otpClear: false,
      showOtpModal: false,
    }
  },
  components: {
    OTPInput: () => import('components/otp-input'),
  },
  computed: {
    isReadOnly() {
      return !!this.formFilled
    },
  },
  created() {
    this.initializeCustomerDetails()
  },
  methods: {
    handleEdit() {
      this.closeOtpModal()
      this.formFilled = false
    },
    initializeCustomerDetails() {
      if (this.currentUser) {
        if (this.currentUser.firstName) {
          this.customerDetails.firstName = this.currentUser.firstName
          this.isFirstNameReadOnly = true
        }
        if (this.currentUser.lastName) {
          this.customerDetails.lastName = this.currentUser.lastName
          this.isLastNameReadOnly = true
        }
        if (this.currentUser.email) {
          this.customerDetails.emailID = this.currentUser.email
          this.isEmailidReadOnly = true
        }
        if (this.currentUser.phoneNumber) {
          this.customerDetails.phoneNumber = this.currentUser.phoneNumber
          this.isPhoneNumberReadOnly = true
        }
        this.isCustomerDetailsReadOnly = true
      }
      // this.isCustomerDetailsReadOnly = !!this.currentUser
    },
    resetForm() {
      this.customerDetails = {
        firstName: this.isCustomerDetailsReadOnly
          ? this.customerDetails.firstName
          : '',
        lastName: this.isCustomerDetailsReadOnly
          ? this.customerDetails.lastName
          : '',
        emailID: this.isEmailidReadOnly ? this.customerDetails.emailID : '',
        phoneNumber: this.isPhoneNumberReadOnly
          ? this.customerDetails.phoneNumber
          : '',
        subject: '',
        message: '',
      }
      // this.isCustomerDetailsReadOnly = !!this.currentUser
      this.formFilled = false
      this.otpRequested = false
      this.resetOtp()
    },
    async handleContinue() {
      this.$refs.form.validate().then(async (valid) => {
        if (valid) {
          if (this.isLoggedIn) {
            this.onSubmit()
          } else {
            if (this.otpAttemptsExceeded) {
              this.otpAttemptsExceeded = false
            }
            this.loadingContinue = true
            await this.requestOtp()
            this.loadingContinue = false
            this.showOtpModal = true
          }
        }
      })
    },
    async requestOtp() {
      this.resending = true

      const otpRequestData = {
        firstName: this.customerDetails.firstName,
        lastName: this.customerDetails.lastName,
        emailID: this.customerDetails.emailID,
      }

      try {
        const response = await this.$store.dispatch(
          'common/requestOtp',
          otpRequestData
        )
        if (response && response.success) {
          this.otpToken = response.data.token
          this.otpRequested = true
          this.formFilled = true
          this.otp = ''
          this.startInterval()
        } else {
          this.showError(
            response.message || 'Failed to request OTP. Please try again.'
          )
        }
      } catch (error) {
        this.showError(
          'An error occurred while requesting OTP. Please try again later.'
        )
      } finally {
        this.resending = false
        this.loading = false
      }
    },
    handleFieldChange() {
      if (this.otpRequested) {
        this.otpRequested = false
        this.formFilled = false
        this.resetOtp()
        this.$refs.form.resetValidation()
      }
    },
    startInterval() {
      if (this.interval) {
        clearInterval(this.interval)
        this.interval = null
      }

      this.resendSeconds = this.defaultResendTime

      this.interval = setInterval(() => {
        this.resendSeconds -= 1
        if (this.resendSeconds <= 0) clearInterval(this.interval)
      }, 1000)
    },
    stopInterval() {
      if (this.interval) {
        clearInterval(this.interval)
        this.interval = null
      }
      this.resendSeconds = 0
    },
    formatOTPSeconds(seconds) {
      let time = new Date(seconds * 1000)
        .toUTCString()
        .match(/(\d\d:\d\d:\d\d)/)[0]
      return time.split(':').splice(1, 2).join(':')
    },
    handleOtpChange(otp) {
      this.otp = otp
    },
    async onSubmit() {
      this.loadingSubmit = true
      let inquiryData = {
        ...this.customerDetails,
        locationId: this.currentLocationId || null,
      }

      if (!this.isLoggedIn) {
        if (!this.otp || !this.otpToken) {
          this.showError('The OTP field is required')
          this.loadingSubmit = false
          return
        }

        inquiryData = {
          ...inquiryData,
          token: this.otpToken,
          otp: this.otp,
        }
      }

      try {
        const response = await this.$store.dispatch(
          'common/submitCustomerInquiry',
          inquiryData
        )
        if (response && response.success) {
          if (this.showOtpModal) {
            this.closeOtpModal()
          }
          this.showSuccessModal = true
          this.resetForm()
          this.resetOtp()
          this.$refs.form.resetValidation()
        } else {
          if (response.otpClear) {
            this.showError('Invalid OTP')
            this.$refs.otpInput.clearInput()
          } else if (response.otpAttemptsExceeded) {
            this.closeOtpModal()
            this.otpAttemptsExceeded = true
            this.showError('Number of OTP attempts exceeded')
            this.handleEdit()
          }
        }
      } catch (error) {
        this.showError(
          'There was an error submitting your inquiry. Please try again later.'
        )
      } finally {
        this.loadingSubmit = false
      }
    },
    closeSuccessModal() {
      this.showSuccessModal = false
    },
    closeOtpModal() {
      this.showOtpModal = false
      this.otpAttemptsExceeded = false
      this.resetOtp()
      this.$refs.form.resetValidation()
    },
    resetOtp() {
      this.otp = ''
      this.otpToken = ''
      this.otpRequested = false
      this.stopInterval()
    },
  },
}
</script>

<style lang="scss">
.cs-form {
  background: #f5f5f5;
  border: 1px solid #d9d9d9;
  padding: 20px 20px;

  .title-h3 {
    font-size: 18px;
    line-height: 1.2;
    font-weight: bold;
  }

  .q-textarea--autogrow {
    textarea {
      min-height: 100px !important;
      max-height: 120px;
    }
  }
  @media (min-width: 1024px) {
    padding: 40px 50px;
    margin-left: 60px;
  }
}
.q-dialog {
  .q-card.cs-success {
    max-width: 420px;
    text-align: center;
    box-shadow: 0 3px 5px rgba($textdark, 0.2);
    -webkit-border-radius: 15px;
    border-radius: 15px;
    .q-card__section {
      padding: 50px 32px;
    }
  }
}
</style>
